import React, { useContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ContextApp } from '../utils/Context';
import { Box, TextField, Typography } from '@mui/material';
import { SidebarIconSVG } from './svg/sidebarIcon.svg.js';
import EastIcon from '@mui/icons-material/East';
import { fetchAllActivitiesByTopicId } from '../utils/API';
import Chat from './Chat';
import { createActivityInTopic } from '../utils/API';
import WalletConnect from './WalletConnect';
import Footer from './Footer';

import useMobileView from '../utils/useMobileView';

import CurrencyStats from '../components/CurrencyStats';
import '../styles/loader.css';

const defaultMessageObject = {
  topicId: '1',
  text: ``,
  isBot: true,
  isNewMessage: false,
};

function ChatContainer() {
  const {
    setShowSlide,
    showSlide,
    setMobile,
    Mobile,
    chatValue,
    setChatValue,
    isLoggedIn,
    setRefetchTopics,
  } = useContext(ContextApp);
  const { mobileView, tabletView } = useMobileView();
  const [message, setMessage] = useState([defaultMessageObject]);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { topicId } = params || {};

  const { data: activitiesObj = {}, refetch: fetchActivities } = useQuery(
    'fetchAllActivitiesByTopicId',
    () => fetchAllActivitiesByTopicId(topicId),
    {
      enabled: !!topicId && isLoggedIn,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  const activities = activitiesObj?.activities ?? [];

  useEffect(() => {
    if (activities.length && topicId) {
      setMessage(activities);
    } else if (topicId && !activities.length) {
      setMessage([defaultMessageObject]);
    }
  }, [activities, topicId]);

  useEffect(() => {
    if (topicId && isLoggedIn) {
      fetchActivities();
    } else {
      setMessage([defaultMessageObject]);
      // navigate('/');
    }
  }, [topicId, isLoggedIn]);

  const handleSend = async (customMessage = '') => {
    const text = chatValue?.trim() || customMessage?.trim();
    if (!text) {
      return;
    }
    setIsLoading(true);
    setChatValue('');
    setMessage([...message, { prompt: text, isBot: false, topicId }]);

    const activityRes = await createActivityInTopic({
      topicId,
      prompt: text,
    });
    const activity = (activityRes?.activities ?? [])[0];
    if (!activity) {
      setIsLoading(false);
      return;
    }
    setMessage([
      ...message,
      { prompt: text, isBot: false, topicId },
      { ...activity, prompt: '', isBot: true, isNewMessage: true },
    ]);
    setIsLoading(false);

    // Refetch topic to update the topic name
    // On first message
    if (activities.length === 0 && message.length <= 1) {
      setRefetchTopics(true);
    }
  };

  const handleKeyPress = () => {
    handleSend();
  };

  return (
    <div
      className={
        showSlide
          ? 'h-[calc(100dvh-32px)] w-[calc(100vw-32px)] flex items-center justify-between flex-col p-2'
          : 'h-[calc(100dvh-32px)] w-[calc(100vw-32px)] lg:w-[calc(100%-300px-32px)] flex items-center justify-between flex-col p-2'
      }
      style={{
        background: '#242424',
        borderRadius: '24px',
        margin: '16px',
      }}
    >
      <div>
        {showSlide && (
          <span
            className="rounded px-3 py-[11px] lg:flex items-start justify-start text-white duration-200 absolute top-2 left-2 z-10"
            title="Open sidebar"
          >
            <div className="mr-auto h-[24px] w-[162.89px] flex items-start justify-between">
              <span
                className="h-[24px] w-[24px] flex cursor-pointer"
                title="Open sidebar"
                onClick={() => setShowSlide(!showSlide)}
              >
                <SidebarIconSVG />
              </span>
              <img
                style={{
                  width: '114.89px',
                  height: '24px',
                  alignItems: 'center',
                  cursor: 'default',
                }}
                src="https://info.zkgpt.io/wp-content/uploads/2024/09/cropped-logo_slim.png"
                alt="gptloto"
              />
            </div>
          </span>
        )}
        <span
          className="rounded px-3 py-[9px] lg:hidden flex items-start justify-start cursor-pointer text-white mt-0 mb-3"
          title="Open sidebar"
          style={{
            position: 'absolute',
            top: 7,
            left: 7,
            zIndex: 2,
            marginTop: 0,
          }}
        >
          <span
            className="h-[24px] w-[24px] flex cursor-pointer"
            title="Open sidebar"
            onClick={() => setMobile(!Mobile)}
          >
            <SidebarIconSVG />
          </span>
          <img
            style={{
              width: '80px',
              height: '18px',
              marginTop: '2px',
              marginLeft: '12px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            src="https://info.zkgpt.io/wp-content/uploads/2024/09/cropped-logo_slim.png"
            alt="gptloto"
          />
        </span>
        {/* {!mobileView && <CurrencyStats showSlide={showSlide} />} */}
      </div>
      {/* chat section */}
      <Chat
        message={message}
        currentTopic={topicId}
        onSuggestedTextClick={(message) => {
          if (isLoggedIn) {
            setChatValue(message);
          }
        }}
        isLoggedIn={isLoggedIn}
        isLoading={isLoading}
      />
      {/* chat input section */}
      <div className="w-full m-auto flex items-center justify-center flex-col gap-2 my-2">
        <span
          className="flex gap-2 items-center justify-center rounded-[12px] border border-[#666666] focus-within:border-[#666666]"
          style={{
            // width: window.innerWidth >= 1900 ? '820px' : '740px',
            width: mobileView
              ? '90%'
              : window.innerWidth >= 1900
              ? '60%'
              : '70%',
            display: mobileView && !isLoggedIn ? 'none' : 'inherit',
          }}
        >
          <TextField
            type="text"
            multiline={true}
            autoFocus
            fullWidth
            size="normal"
            maxRows={10}
            sx={{
              minHeight: '56px',
              fontWeight: 400,
              fontSize: '16px',
              '& fieldset': { border: 'none' },
              '& textarea': {
                color: '#eee',
                overflow: 'scroll',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              },
              '& .MuiInputBase-input.Mui-disabled': {
                '-webkit-text-fill-color': '#666666',
              },
            }}
            placeholder={
              isLoggedIn ? 'Send a message' : 'Please connect your wallet'
            }
            value={chatValue}
            onChange={(e) => {
              setChatValue(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter' && e.shiftKey === false) {
                setIsLoading(true);
                handleKeyPress();
              }
            }}
            disabled={!isLoggedIn || isLoading}
          />
          {!isLoading && (
            <Box
              title="send message"
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '12px',
                ml: 'auto',
                mr: 1,
                mt: 'auto',
                mb: 1,
                backgroundColor: isLoggedIn ? '#17B5AD' : '#666666',
                cursor: isLoggedIn ? 'pointer' : 'auto',
                gap: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EastIcon
                sx={{
                  width: '24px',
                  height: '24px',
                  color: isLoggedIn ? '#FFFFFF' : '#999999',
                }}
                onClick={isLoggedIn && chatValue.length > 0 ? handleSend : null}
              />
            </Box>
          )}
          {isLoggedIn && isLoading && (
            <Box
              title="send message"
              sx={{
                width: '40px',
                height: '40px',
                borderRadius: '12px',
                ml: 'auto',
                mr: 1,
                gap: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div class="loader"></div>
            </Box>
          )}
        </span>
        {mobileView && !isLoggedIn && (
          <div className="w-full border-t border-gray-600 flex flex-col gap-2 items-center justify-center p-2">
            <WalletConnect />
          </div>
        )}
        <p className="lg:text-xs text-[10px] text-gray-400 text-left text-[10px] font-normal text-[12px] leading-[18px]">
          Build for Web3, powered by
          <a
            href="https://info.zkgpt.io/"
            target="_blank"
            rel="noreferrer"
            style={{ color: '#17B5AD', marginLeft: 4 }}
          >
            <u>ZKGPT</u>
          </a>
          .
        </p>
        <Footer />
      </div>
    </div>
  );
}

export default ChatContainer;
