import { TwitterIconSVG } from './svg/twitterIconX.svg.js';
import { NoteIconSVG } from './svg/noteIconSvg.svg.js';
import { TelegramIconSVG } from './svg/telegramIcon.svg.js';

const Footer = () => {
  return (
    <div
      style={{
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        gap: '25px',
      }}
    >
      <a href="https://t.me/zkgptio" target="_blank">
        <TelegramIconSVG
          width="24"
          height="24"
          style={{ marginRight: '8px' }}
        />
      </a>
      <a href="https://x.com/zkgptio" target="_blank">
        <TwitterIconSVG width="24" height="24" style={{ marginRight: '8px' }} />
      </a>
      <a href="https://zkgpt-docs-1.gitbook.io/" target="_blank">
        <NoteIconSVG width="22" height="22" style={{ marginRight: '8px' }} />
      </a>
    </div>
  );
};

export default Footer;
